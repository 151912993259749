var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{},_vm._l((_vm.card),function(dataCard,index){return _c('b-col',{key:index,staticClass:"p-0 mt-1",class:{'pr-1': index == 1},attrs:{"cols":dataCard.col,"lg":dataCard.lg}},[_c('SecondaryCard',{attrs:{"title":_vm.$t(dataCard.title)}},[_c('b-row',_vm._l((dataCard.form),function(form,indexForm){return _c('b-col',{key:indexForm,attrs:{"cols":dataCard.formCols,"lg":dataCard.formColsLg}},[(form.type == 'single')?_c('b-form-group',{attrs:{"label":_vm.$t(form.label)}},[_c('ValidationProvider',{attrs:{"name":_vm.$t(form.label),"rules":form.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(form.option)?[_c('LittleTabs',{attrs:{"tabs":form.tabs,"buttonSize":"sm","containerStyle":"justify-content-start","btnClass":"tabWrapperSmall2","switchTabs":_vm.switchTabs}})]:[(
                                                form.hasOwnProperty('notes')
                                            )?_c('span',[_c('b-form-textarea',{attrs:{"size":"lg"}})],1):_c('span',[_c('b-form-input',{attrs:{"placeholder":_vm.$t(form.label)},model:{value:(form.value),callback:function ($$v) {_vm.$set(form, "value", $$v)},expression:"form.value"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0])}})],1)]]}}],null,true)})],1):_c('b-row',_vm._l((dataCard
                                        .form[indexForm].form),function(subForm,index){return _c('b-col',{key:index,attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t(subForm.label)}},[_c('ValidationProvider',{attrs:{"name":_vm.$t(subForm.label),"rules":subForm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t(subForm.label)},model:{value:(subForm.value),callback:function ($$v) {_vm.$set(subForm, "value", $$v)},expression:"subForm.value"}}),_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"10px"},domProps:{"textContent":_vm._s(errors[0])}})]}}],null,true)})],1)],1)}),1)],1)}),1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }