<template>
    <div>
        <b-container fluid>
            <b-row>
                <b-col
                    v-for="(cardData, indexCard) in card"
                    :key="indexCard"
                    :cols="cardData.col"
                    :lg="cardData.lg"
                    class="p-0 mt-1"
                    :class="{'pr-1': indexCard == 1}"
                >
                    <SecondaryCard :title="$t(cardData.cardTitle)">
                        <b-row>
                            <b-col
                                v-for="(form, indexForm) in cardData.form"
                                :key="indexForm"
                                cols="12"
                                :lg="cardData.formLgCol"
                            >
                                <p
                                    class="h4 mb-2"
                                    v-text="$t(form.formLabel)"
                                />
                                <span
                                    v-for="(input, inputIndex) in form.property"
                                    :key="inputIndex"
                                >
                                    <b-form-group
                                        v-if="input.type == 'single'"
                                        :label="$t(input.label)"
                                    >
                                        <ValidationProvider
                                            :name="$t(input.label)"
                                            #default="{errors}"
                                            :rules="input.rules"
                                        >
                                            <template v-if="input.option">
                                                <LittleTabs
                                                    :tabs="input.tabs"
                                                    buttonSize="sm"
                                                    containerStyle="justify-content-start"
                                                    btnClass="tabWrapperSmall2"
                                                    :switchTabs="switchTabs"
                                                />
                                            </template>

                                            <template v-else>
                                                <b-form-input
                                                    :placeholder="
                                                        $t(input.label)
                                                    "
                                                />
                                                <small
                                                    class="text-danger"
                                                    v-text="errors[0]"
                                                />
                                            </template>
                                        </ValidationProvider>
                                    </b-form-group>

                                    <b-row v-else no-gutters>
                                        <b-col
                                            cols="6"
                                            v-for="(
                                                subForm, index
                                            ) in input.input"
                                            :key="index"
                                        >
                                            <b-form-group
                                                :label="$t(subForm.label)"
                                                :class="{
                                                    'mr-1': index !== 1,
                                                }"
                                            >
                                                <ValidationProvider
                                                    #default="{errors}"
                                                    :name="$t(subForm.label)"
                                                    :rules="subForm.rules"
                                                >
                                                    <b-form-input
                                                        :placeholder="
                                                            $t(subForm.label)
                                                        "
                                                        v-model="subForm.value"
                                                    />
                                                    <small
                                                        class="text-danger"
                                                        style="font-size: 10px"
                                                        v-text="errors[0]"
                                                    />
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </span>
                            </b-col>
                        </b-row>
                    </SecondaryCard>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import {ValidationProvider} from 'vee-validate';
export default {
    name: 'invoices',
    components: {ValidationProvider},
    data() {
        return {
            card: [
                {
                    col: 12,
                    lg: 12,
                    formLgCol: 3,
                    cardTitle: 'CustomersCardEdit.invoice',
                    form: [
                        {
                            formLabel: 'InvoiceCardCustomerEdit.paymentLabel',
                            property: [
                                {
                                    label: 'InvoiceCardCustomerEdit.paymentTerms',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.deliveryTerms',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.deliveryMethod',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.interestBilling',
                                    value: '',
                                    rules: 'require',
                                    option: true,
                                    type: 'single',
                                    tabs: [
                                        {
                                            label: 'CustomersCardEdit.yes',
                                            active: true,
                                            value: 'yes',
                                        },
                                        {
                                            label: 'CustomersCardEdit.no',
                                            active: false,
                                            value: 'no',
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            formLabel: 'InvoiceCardCustomerEdit.invoicing',
                            property: [
                                {
                                    rules: 'require',
                                    option: false,
                                    type: 'array',
                                    input: [
                                        {
                                            label: 'InvoiceCardCustomerEdit.priceList',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                        {
                                            label: 'InvoiceCardCustomerEdit.currency',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                    ],
                                },

                                {
                                    label: 'InvoiceCardCustomerEdit.invoiceDiscount',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },

                                {
                                    rules: 'require',
                                    option: false,
                                    type: 'array',
                                    input: [
                                        {
                                            label: 'InvoiceCardCustomerEdit.invoiceCharge',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                        {
                                            label: 'InvoiceCardCustomerEdit.shippingCharge',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                    ],
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.priceVat',
                                    value: '',
                                    rules: 'require',
                                    option: true,
                                    type: 'single',
                                    tabs: [
                                        {
                                            label: 'CustomersCardEdit.yes',
                                            active: true,
                                            value: 'yes',
                                        },
                                        {
                                            label: 'CustomersCardEdit.no',
                                            active: false,
                                            value: 'no',
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            formLabel: 'InvoiceCardCustomerEdit.reference',
                            property: [
                                {
                                    label: 'InvoiceCardCustomerEdit.ourReference',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.yourReference',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.customerManage',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.externalReference',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                            ],
                        },

                        {
                            formLabel: 'InvoiceCardCustomerEdit.accounting',
                            property: [
                                {
                                    label: 'InvoiceCardCustomerEdit.url',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },

                                {
                                    label: 'InvoiceCardCustomerEdit.typeOfVat',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                                {
                                    rules: '',
                                    option: false,
                                    type: 'array',
                                    input: [
                                        {
                                            label: 'InvoiceCardCustomerEdit.salesAccount',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                        {
                                            label: 'InvoiceCardCustomerEdit.vatNumber',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                    ],
                                },

                                {
                                    rules: '',
                                    option: false,
                                    type: 'array',
                                    input: [
                                        {
                                            label: 'InvoiceCardCustomerEdit.costCentre',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                        {
                                            label: 'InvoiceCardCustomerEdit.project',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },

                {
                    col: 12,
                    lg: 9,
                    formLgCol: 4,
                    cardTitle: 'InvoiceCardCustomerEdit.einvoiceDetail',
                    form: [
                        {
                            formLabel: 'InvoiceCardCustomerEdit.edocument',
                            property: [
                                {
                                    label: 'InvoiceCardCustomerEdit.deliveryType',
                                    value: '',
                                    rules: 'require',
                                    option: true,
                                    type: 'single',
                                    tabs: [
                                        {
                                            label: 'Message.tableHeader.email',
                                            active: true,
                                            value: 'email',
                                        },
                                        {
                                            label: 'InvoiceCardCustomerEdit.print',
                                            active: false,
                                            value: 'print',
                                        },
                                    ],
                                },
                                {
                                    label: 'Message.tableHeader.email',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.confidentialCopy',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.glnNumber',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.glnNumberDelivery',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                            ],
                        },

                        {
                            formLabel: 'InvoiceCardCustomerEdit.quote',
                            property: [
                                {
                                    label: 'InvoiceCardCustomerEdit.deliveryType',
                                    value: '',
                                    rules: 'require',
                                    option: true,
                                    type: 'single',
                                    tabs: [
                                        {
                                            label: 'Message.tableHeader.email',
                                            active: true,
                                            value: 'email',
                                        },
                                        {
                                            label: 'InvoiceCardCustomerEdit.print',
                                            active: false,
                                            value: 'print',
                                        },
                                    ],
                                },
                                {
                                    label: 'Message.tableHeader.email',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.confidentialCopy',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.glnNumber',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.invoiceText',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                            ],
                        },
                        {
                            formLabel: 'InvoiceCardCustomerEdit.order',
                            property: [
                                {
                                    label: 'InvoiceCardCustomerEdit.deliveryType',
                                    value: '',
                                    rules: 'require',
                                    option: true,
                                    type: 'single',
                                    tabs: [
                                        {
                                            label: 'Message.tableHeader.email',
                                            active: true,
                                            value: 'email',
                                        },
                                        {
                                            label: 'InvoiceCardCustomerEdit.print',
                                            active: false,
                                            value: 'print',
                                        },
                                    ],
                                },
                                {
                                    label: 'Message.tableHeader.email',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.confidentialCopy',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.glnNumber',
                                    value: '',
                                    rules: 'require',
                                    option: false,
                                    type: 'single',
                                },
                            ],
                        },
                    ],
                },

                {
                    col: 12,
                    lg: 3,
                    formLgCol: 12,
                    cardTitle: 'InvoiceCardCustomerEdit.template',
                    form: [
                        {
                            formLabel: 'InvoiceCardCustomerEdit.preselcted',
                            property: [
                                {
                                    label: 'InvoiceCardCustomerEdit.invoice',
                                    rules: 'require',
                                    option: false,
                                    type: 'array',
                                    input: [
                                        {
                                            label: 'InvoiceCardCustomerEdit.priceList',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                        {
                                            label: 'InvoiceCardCustomerEdit.currency',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                    ],
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.invoice',
                                    rules: 'require',
                                    option: false,
                                    type: 'array',
                                    input: [
                                        {
                                            label: 'InvoiceCardCustomerEdit.priceList',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                        {
                                            label: 'InvoiceCardCustomerEdit.currency',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                    ],
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.invoice',
                                    rules: 'require',
                                    option: false,
                                    type: 'array',
                                    input: [
                                        {
                                            label: 'InvoiceCardCustomerEdit.priceList',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                        {
                                            label: 'InvoiceCardCustomerEdit.currency',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                    ],
                                },
                                {
                                    label: 'InvoiceCardCustomerEdit.invoice',
                                    rules: 'require',
                                    option: false,
                                    type: 'array',
                                    input: [
                                        {
                                            label: 'InvoiceCardCustomerEdit.priceList',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                        {
                                            label: 'InvoiceCardCustomerEdit.currency',
                                            value: '',
                                            rules: 'require',
                                            option: false,
                                            type: 'single',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        };
    },

    methods: {
        switchTabs(index) {
        },
    },
};
</script>

<style scoped></style>
