<template>
    <div>
        <b-container fluid>
            <b-row class="">
                <b-col
                    v-for="(dataCard, index) in card"
                    :key="index"
                    :cols="dataCard.col"
                    :lg="dataCard.lg"
                    class="p-0 mt-1"
                    :class="{'pr-1': index == 1}"
                >
                    <SecondaryCard :title="$t(dataCard.title)">
                        <b-row>
                            <b-col
                                v-for="(form, indexForm) in dataCard.form"
                                :key="indexForm"
                                :cols="dataCard.formCols"
                                :lg="dataCard.formColsLg"
                            >
                                <b-form-group
                                    v-if="form.type == 'single'"
                                    :label="$t(form.label)"
                                >
                                    <ValidationProvider
                                        #default="{errors}"
                                        :name="$t(form.label)"
                                        :rules="form.rules"
                                    >
                                        <template v-if="form.option">
                                            <LittleTabs
                                                :tabs="form.tabs"
                                                buttonSize="sm"
                                                containerStyle="justify-content-start"
                                                btnClass="tabWrapperSmall2"
                                                :switchTabs="switchTabs"
                                            />
                                        </template>
                                        <template v-else>
                                            <span
                                                v-if="
                                                    form.hasOwnProperty('notes')
                                                "
                                            >
                                                <b-form-textarea size="lg" />
                                            </span>
                                            <span v-else>
                                                <b-form-input
                                                    :placeholder="
                                                        $t(form.label)
                                                    "
                                                    v-model="form.value"
                                                />
                                                <small
                                                    class="text-danger"
                                                    v-text="errors[0]"
                                                />
                                            </span>
                                        </template>
                                    </ValidationProvider>
                                </b-form-group>

                                <b-row v-else>
                                    <b-col
                                        cols="6"
                                        v-for="(subForm, index) in dataCard
                                            .form[indexForm].form"
                                        :key="index"
                                    >
                                        <b-form-group
                                            :label="$t(subForm.label)"
                                        >
                                            <ValidationProvider
                                                #default="{errors}"
                                                :name="$t(subForm.label)"
                                                :rules="subForm.rules"
                                            >
                                                <b-form-input
                                                    :placeholder="
                                                        $t(subForm.label)
                                                    "
                                                    v-model="subForm.value"
                                                />
                                                <small
                                                    class="text-danger"
                                                    style="font-size: 10px"
                                                    v-text="errors[0]"
                                                />
                                            </ValidationProvider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </SecondaryCard>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import {ValidationProvider} from 'vee-validate';
export default {
    components: {ValidationProvider},
    data() {
        return {
            card: [
                {
                    title: 'CustomersCardEdit.customerDetail',
                    col: '12',
                    lg: '12',
                    formCols: '12',
                    formColsLg: '3',
                    form: [
                        {
                            label: 'CustomersCardEdit.firstForm.custnumber',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.firstForm.customerType',
                            rules: 'required',
                            option: true,
                            type: 'single',
                            tabs: [
                                {
                                    label: 'CustomersCardEdit.company',
                                    active: true,
                                    value: 'company',
                                },
                                {
                                    label: 'CustomersCardEdit.private',
                                    active: false,
                                    value: 'private',
                                },
                            ],
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.firstForm.organisationLabel',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.firstForm.active',
                            rules: 'required',
                            option: true,
                            type: 'single',
                            tabs: [
                                {
                                    label: 'CustomersCardEdit.yes',
                                    active: true,
                                    value: 'yes',
                                },
                                {
                                    label: 'CustomersCardEdit.no',
                                    active: false,
                                    value: 'no',
                                },
                            ],
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.firstForm.name',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.firstForm.phone1',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.firstForm.phone2',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.firstForm.email',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.firstForm.url',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.firstForm.address1',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.firstForm.address2',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },

                        {
                            label: 'CustomersCardEdit.firstForm.url',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.firstForm.fax',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            option: false,
                            type: 'array',
                            form: [
                                {
                                    label: 'CustomersCardEdit.firstForm.zipCode',
                                    rules: 'required',
                                    option: false,
                                    type: 'single',
                                    value: '',
                                },
                                {
                                    label: 'CustomersCardEdit.firstForm.city',
                                    rules: 'required',
                                    option: false,
                                    type: 'single',
                                    value: '',
                                },
                            ],
                        },
                        {
                            label: 'CustomersCardEdit.firstForm.country',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                    ],
                },

                {
                    title: 'CustomersCardEdit.deliverAddress',
                    col: '12',
                    lg: '6',
                    formCols: '12',
                    formColsLg: '6',
                    form: [
                        {
                            label: 'CustomersCardEdit.secondForm.name',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.secondForm.phone1',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.secondForm.phone2',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.secondForm.invoice1',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.secondForm.invoice2',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            option: false,
                            type: 'array',
                            form: [
                                {
                                    label: 'CustomersCardEdit.secondForm.zipCode',
                                    rules: 'required',
                                    option: false,
                                    type: 'single',
                                    value: '',
                                },
                                {
                                    label: 'CustomersCardEdit.secondForm.city',
                                    rules: 'required',
                                    option: false,
                                    type: 'single',
                                    value: '',
                                },
                            ],
                        },
                    ],
                },
                {
                    title: 'CustomersCardEdit.visitingAddress',
                    col: '12',
                    lg: '6',
                    formCols: '12',
                    formColsLg: '6',
                    form: [
                        {
                            label: 'CustomersCardEdit.thirdForm.name',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.thirdForm.phone1',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.thirdForm.phone2',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.thirdForm.address1',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.thirdForm.address2',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            option: false,
                            type: 'array',
                            form: [
                                {
                                    label: 'CustomersCardEdit.thirdForm.zipCode',
                                    rules: 'required',
                                    option: false,
                                    type: 'single',
                                    value: '',
                                },
                                {
                                    label: 'CustomersCardEdit.thirdForm.city',
                                    rules: 'required',
                                    option: false,
                                    type: 'single',
                                    value: '',
                                },
                            ],
                        },
                    ],
                },

                {
                    title: 'CustomersCardEdit.memberDetails',
                    col: '12',
                    formCols: '12',
                    formColsLg: '6',
                    form: [
                        {
                            label: 'CustomersCardEdit.forthForm.sni',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.forthForm.cfar',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.forthForm.shopId',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                        },
                        {
                            label: 'CustomersCardEdit.forthForm.notes',
                            rules: 'required',
                            option: false,
                            type: 'single',
                            value: '',
                            notes: true,
                        },
                    ],
                },
            ],
        };
    },

    methods: {
        switchTabs(index) {
        },
    },
};
</script>

<style scoped></style>
